import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import RoleBasedGuard from "../guards/RoleBasedGuard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/app")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();
  return useRoutes([
    {
      path: "/",
      element: <RoleBasedGuard isRoute />,
    },

    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "password-recovery",
          element: (
            <GuestGuard>
              <PasswordRecovery />
            </GuestGuard>
          ),
        },
      ],
    },

    // Super Admin Routes
    {
      path: "super-admin",
      element:
        pathname === "/super-admin" ? (
          <Navigate to="/super-admin/users/all" replace />
        ) : (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      children: [
        {
          path: "users",
          children: [
            {
              path: "all",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <Users />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <CreateUser />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <EditUser />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "clone-user-data",
          element: (
            <RoleBasedGuard role={"superadmin"} hasContent>
              <CloneUserData />
            </RoleBasedGuard>
          ),
        },
        {
          path: "release",
          children: [
            {
              path: "all",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <Releases />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <CreateRelease />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <EditRelease />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "configs",
          children: [
            {
              path: "twilio",
              element: (
                <RoleBasedGuard role={"superadmin"} hasContent>
                  <TwilioConfig />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },

    // Dashboard Routes
    {
      path: "app",
      element:
        pathname === "/app" ? (
          <Navigate to="/app/dashboard" replace />
        ) : (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      children: [
        {
          path: "dashboard",
          element: (
            <RoleBasedGuard role={"user"} hasContent>
              <Dashboard />
            </RoleBasedGuard>
          ),
        },
        {
          path: "changepassword",
          element: <ChangePassword />,
        },
        {
          path: "orders",
          children: [
            {
              path: "all",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Orders />
                </RoleBasedGuard>
              ),
            },
            {
              path: "view",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <ViewOrder />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "items",
          children: [
            {
              path: "all-items",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Items />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create-item",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CreateItem />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit-item",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <EditItem />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: "categories",
          children: [
            {
              path: "all-categories",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Categories />
                </RoleBasedGuard>
              ),
            },
            {
              path: "arrange",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <ArrangeCategories />
                </RoleBasedGuard>
              ),
            },
            {
              path: "custom-hours",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CustomHours />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create-custom-hours",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CreateCustomHours />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit-custom-hours",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <EditCustomHours />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create-category",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CreateCategory />
                </RoleBasedGuard>
              ),
            },
            {
              path: "view-category",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <ViewCategory />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "modifiers",
          children: [
            {
              path: "all-modifiers",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <AllModifiers />
                </RoleBasedGuard>
              ),
            },
            {
              path: "all-modifier-sets",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <AllModifierSets />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create-modifier",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CreateModifier />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit-modifier",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <EditModifier />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create-modifier-set",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CreateModifierSet />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit-modifier-set",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <EditModifierSet />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "sales-reports",
          element: (
            <RoleBasedGuard role={"user"} hasContent>
              <SalesReports />
            </RoleBasedGuard>
          ),
        },
        {
          path: "tax-reports",
          element: (
            <RoleBasedGuard role={"user"} hasContent>
              <TaxReports />
            </RoleBasedGuard>
          ),
        },
        {
          path: "taxes",
          children: [
            {
              path: "all-taxes",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Taxes />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create-tax",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <CreateTax />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit-tax",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <EditTax />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "settings",
          children: [
            {
              path: "kiosk",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Kiosk />
                </RoleBasedGuard>
              ),
            },
            {
              path: "terminals",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Terminals />
                </RoleBasedGuard>
              ),
            },
            {
              path: "terminal",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Terminal />
                </RoleBasedGuard>
              ),
            },
            {
              path: "receipt",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Receipt />
                </RoleBasedGuard>
              ),
            },
            {
              path: "printer",
              element: (
                <RoleBasedGuard role={"user"} hasContent>
                  <Printer />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },

    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const ChangePassword = Loadable(lazy(() => import("../pages/ChangePassword")));
const PasswordRecovery = Loadable(
  lazy(() => import("../pages/auth/PasswordRecovery"))
);
// OVERVIEW
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const Orders = Loadable(lazy(() => import("../pages/transactions/Orders")));
const ViewOrder = Loadable(
  lazy(() => import("../pages/transactions/ViewOrder"))
);
// ITEMS
const Items = Loadable(lazy(() => import("../pages/items/Items")));
const Categories = Loadable(
  lazy(() => import("../pages/categories/Categories"))
);
const ArrangeCategories = Loadable(
  lazy(() => import("../pages/categories/ArrangeCategories"))
);
const CustomHours = Loadable(
  lazy(() => import("../pages/categories/CustomHours"))
);
const CreateCustomHours = Loadable(
  lazy(() => import("../pages/categories/CreateCustomHours"))
);
const EditCustomHours = Loadable(
  lazy(() => import("../pages/categories/EditCustomHours"))
);
const CreateItem = Loadable(lazy(() => import("../pages/items/CreateItem")));
const CreateCategory = Loadable(
  lazy(() => import("../pages/categories/CreateCategory"))
);
const EditItem = Loadable(lazy(() => import("../pages/items/EditItem")));
const ViewCategory = Loadable(
  lazy(() => import("../pages/categories/ViewCategory"))
);
// RELEASES
const Releases = Loadable(lazy(() => import("../pages/releases/Releases")));
const CreateRelease = Loadable(
  lazy(() => import("../pages/releases/CreateRelease"))
);
const EditRelease = Loadable(
  lazy(() => import("../pages/releases/EditRelease"))
);
// Reports
const SalesReports = Loadable(lazy(() => import("../pages/reports/Sales")));
const TaxReports = Loadable(lazy(() => import("../pages/reports/Taxes")));
// SUPER ADMIN
const Users = Loadable(lazy(() => import("../pages/users/Users")));
const CloneUserData = Loadable(
  lazy(() => import("../pages/users/CloneUserData"))
);
const CreateUser = Loadable(lazy(() => import("../pages/users/CreateUser")));
const EditUser = Loadable(lazy(() => import("../pages/users/EditUser")));
const TwilioConfig = Loadable(
  lazy(() => import("../pages/settings/TwilioConfig"))
);
// TAXES
const Taxes = Loadable(lazy(() => import("../pages/taxes/Taxes")));
const CreateTax = Loadable(lazy(() => import("../pages/taxes/CreateTax")));
const EditTax = Loadable(lazy(() => import("../pages/taxes/EditTax")));
// SETTINGS
const Kiosk = Loadable(lazy(() => import("../pages/settings/Kiosk")));
const Terminal = Loadable(lazy(() => import("../pages/settings/Terminal")));
const Terminals = Loadable(lazy(() => import("../pages/settings/Terminals")));
const Printer = Loadable(lazy(() => import("../pages/settings/Printer")));
const Receipt = Loadable(lazy(() => import("../pages/settings/Receipt")));
// MODIFIERS
const AllModifiers = Loadable(
  lazy(() => import("../pages/modifiers/AllModifiers"))
);
const AllModifierSets = Loadable(
  lazy(() => import("../pages/modifiers/AllModifierGroups"))
);
const CreateModifier = Loadable(
  lazy(() => import("../pages/modifiers/CreateModifier"))
);
const CreateModifierSet = Loadable(
  lazy(() => import("../pages/modifiers/CreateModifierGroup"))
);
const EditModifier = Loadable(
  lazy(() => import("../pages/modifiers/EditModifier"))
);
const EditModifierSet = Loadable(
  lazy(() => import("../pages/modifiers/EditModifierGroup"))
);
