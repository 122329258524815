// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  menuItem: getIcon("ic_menu_item"),
};

const navConfig = [
  // SuperAdmin
  {
    subheader: "super admin",
    role: "superadmin",
    items: [
      {
        title: "users",
        path: "/super-admin/users/all",
        icon: ICONS.user,
      },
      {
        title: "Clone User Data",
        path: "/super-admin/clone-user-data",
        icon: ICONS.user,
      },
      {
        title: "releases",
        path: "/super-admin/release/all",
        icon: ICONS.dashboard,
      },
    ],
  },

  // Overview
  // ----------------------------------------------------------------------
  {
    subheader: "overview",
    role: "user",
    items: [
      {
        title: "dashboard",
        path: "/app/dashboard",
        icon: ICONS.analytics,
      },
      {
        title: "orders",
        path: "/app/orders/all",
        icon: ICONS.banking,
      },
    ],
  },

  // Items
  // ----------------------------------------------------------------------
  {
    subheader: "Item Catalog",
    role: "user",
    items: [
      {
        title: "items",
        path: "/app/items/all-items",
        icon: ICONS.ecommerce,
      },
      {
        title: "categories",
        path: "/app/categories",
        icon: ICONS.menuItem,
        children: [
          { title: "All Categories", path: "/app/categories/all-categories" },
          { title: "Arrange Categories", path: "/app/categories/arrange" },
          { title: "Custom Hours", path: "/app/categories/custom-hours" },
        ],
      },
      {
        title: "modifiers",
        path: "/app/modifiers",
        icon: ICONS.menuItem,
        children: [
          { title: "Modifier Sets", path: "/app/modifiers/all-modifier-sets" },
          { title: "Modifiers", path: "/app/modifiers/all-modifiers" },
        ],
      },
      {
        title: "taxes",
        path: "/app/taxes/all-taxes",
        icon: ICONS.invoice,
      },
    ],
  },

  // Reports
  // ----------------------------------------------------------------------
  {
    subheader: "reports",
    role: "user",
    items: [
      {
        title: "sales reports",
        path: "/app/sales-reports",
        icon: ICONS.invoice,
      },
      {
        title: "tax reports",
        path: "/app/tax-reports",
        icon: ICONS.invoice,
      },
    ],
  },
  {
    subheader: "Configurations",
    role: "superadmin",
    items: [
      {
        title: "Twilio",
        path: "/super-admin/configs/twilio",
        icon: ICONS.chat,
      },
    ],
  },
  {
    subheader: "settings",
    role: "user",
    items: [
      {
        title: "Kiosk",
        path: "/app/settings/kiosk",
        icon: ICONS.dashboard,
      },
      {
        title: "Terminals (NEW)",
        path: "/app/settings/terminals",
        icon: ICONS.kanban,
      },
      {
        title: "Terminal",
        path: "/app/settings/terminal",
        icon: ICONS.kanban,
      },
      {
        title: "Receipt",
        path: "/app/settings/receipt",
        icon: ICONS.invoice,
      },
      {
        title: "Printer",
        path: "/app/settings/printer",
        icon: ICONS.invoice,
      },
    ],
  },
];

export default navConfig;
